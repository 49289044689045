// extracted by mini-css-extract-plugin
export var border1 = "cS_hV";
export var border2 = "cS_hW";
export var border3 = "cS_hX";
export var border4 = "cS_hY";
export var container = "cS_f";
export var dark = "cS_dv";
export var large = "cS_cv";
export var ldsRing = "cS_hZ";
export var light = "cS_dt";
export var medium = "cS_cw";
export var primaryPressed = "cS_h0";
export var xlarge = "cS_hT";
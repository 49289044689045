// extracted by mini-css-extract-plugin
export var button = "Z_j";
export var children = "Z_cz";
export var disabled = "Z_cr";
export var fullWidth = "Z_cy";
export var iconWrapper = "Z_cC";
export var large = "Z_cv";
export var link = "Z_b0";
export var loading = "Z_cq";
export var loadingContainer = "Z_cB";
export var medium = "Z_cw";
export var primary = "Z_cp";
export var secondary = "Z_cs";
export var small = "Z_cx";
export var tertiary = "Z_ct";